<template>
  <div class="container-page">
    <p class="title-inner">Запись реестра таможенных пошлин</p>
    <div class="row">
      <div class="form-group required col-6">
        <ComponentInput
          label="Страна-импортер"
          :select2Options="countries"
          v-model="card.reporter"
          :select2Settings="select2Settings"
          type="select2"
          required
        />
      </div>
      <div class="form-group required col-6">
        <ComponentInput
          label="Страна-экспортер"
          :select2Options="countries"
          v-model="card.partner"
          :select2Settings="select2Settings"
          type="select2"
          required
          :disabled="id > 0"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group required col-4">
        <ComponentInput v-model="card.year" type="year" label="Год" />
      </div>
      <div class="form-group required col-4">
        <ComponentInput v-model="card.product" label="Код ТНВЭД группы товаров" />
      </div>
      <div class="form-group required col-4">
        <ComponentInput v-model="card.ntlcCode" label="Код ТНВЭД" />
      </div>
    </div>
    <div class="form-group required">
      <ComponentInput v-model="card.ntlcDescription" type="textarea" label="Описание товара" />
    </div>
    <div class="row">
      <div class="form-group col-4">
        <ComponentInput v-model="card.tariffRegime" label="Торговый режим" />
      </div>
      <div class="form-group col-4">
        <ComponentInput v-model="card.tariffReported" label="Применяемый тариф" />
      </div>
      <div class="form-group col-4">
        <ComponentInput v-model="card.tariffAve" label="Адвалорный эквивалент" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-4">
        <ComponentInput
          label="Источник данных"
          :select2Options="sources"
          v-model="card.sourceId"
          :select2Settings="select2Settings"
          type="select2"
        />
      </div>
      <div class="form-group col-8">
        <ComponentInput v-model="card.taxDescription" label="Описание налогов и иных ограничений" />
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">Файл</label>
      <FileUpload width="100%" :entity="card" @deleteFile="removeFile" prop-name="file" @uploaded="fileUploaded" />
    </div>
    <div class="checkboxInput">
      <input type="checkbox" v-model="card.isActive" id="chActive" />
      <label for="chActive">Запись активна</label>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import FileUpload from '../../../common/components/FileUpload';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import Api from '../api/macmaporgcustomsduties';

  export default {
    name: 'MacmaporgCustomsDutyCard',
    components: { FileUpload, ButtonStock, ComponentInput },
    data() {
      return {
        id: this.$route.params.id,
        card: {},
        countries: [],
        select2Settings: Constants.select2Settings,
        sources: [
          { id: 1, text: 'Trade map' },
          { id: 2, text: 'ВТО' },
        ],
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id)
            .then((response) => {
              this.card = response.data;
            })
            .catch((error) => {
              console.error(error?.response?.data);
            });
        }
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data
              .filter((x) => x.codeForDuties != null)
              .map((x) => {
                return { text: x.name, id: x.codeForDuties };
              });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.card.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        if (this.id > 0) {
          Api.update(this.card)
            .then(() => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              if (this.card.id) {
                this.loadCard();
              } else {
                this.$router.push({ name: 'MacmaporgCustomsDuties' });
              }
            })
            .catch(() => {
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            });
        } else {
          Api.create(this.card)
            .then(() => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              this.$router.push({ name: 'MacmaporgCustomsDuties' });
            })
            .catch(() => {
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            });
        }
      },
      fileUploaded(e) {
        this.card.file = e.data;
        this.card.fileId = e.data ? e.data.id : null;
      },
      removeFile() {
        this.card.file = {};
        this.card.fileId = null;
      },
      close() {
        this.$router.back();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style scoped></style>
