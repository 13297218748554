import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/macmaporgcustomsduties/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/macmaporgcustomsduties/find/${id}`);
  },
  update: function (request) {
    return axios.put(`/api/macmaporgcustomsduties/${request.id}`, request);
  },
  create: function (request) {
    return axios.post(`/api/macmaporgcustomsduties/`, request);
  },
  runIntegration: function () {
    return axios.post('/api/macmaporgcustomsduties/runintegration');
  },
  cancelIntegration: function () {
    return axios.post('/api/macmaporgcustomsduties/cancelintegration');
  },
  getIntegrationState: function () {
    return axios.get('/api/macmaporgcustomsduties/integrationstate');
  },
};
